/* Add space at bottom */
/* #content {
    padding-bottom: 50px;
} */

#social-container {
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding: 5px;
    z-index: 10;
    width: 100%;
}

#social-container img {
    padding: 7px;
    height: 20px;
    width: auto;
    transition: height 0.1s;
}
