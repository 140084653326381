.crimee-no-7-page {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  width: 100vw;
  height: 100vh;
}

.background-img {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -30;
  opacity: 0.1;
}

.crimee-title {
  text-align: center;
  /* font-family: 'Special Elite', 'Courier New', Courier, monospace; */
}

.crimee-background {
  width: calc(max(100vw, 600px));
  color: black;
  position: fixed;
  top: 35px;
  left: 0;
  font-size: 50pt;
  z-index: -1;
}

.crimee-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: none;
}

.image-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.album-cover-container {
  margin: 20px;
  width: calc(min(300px, 100vw));
  height: calc(min(300px, 100vw));
  background-size: contain;
  position: relative;
}

.album-cover-image-large {
  padding: 0;
  width: inherit;
}

.toggle-image-inner-container {
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(255, 254, 254, 0.25);
  position: absolute;
  top: 0;
  left: 0;
}

.crimee-text-container {
  width: calc(min(800px, 95%));
  background-color: rgba(186, 186, 186, 0.5);
  padding: 10px;
}

.crimee-quote {
  font-size: large;
  color: white;
  background-color: rgb(40, 40, 40);
  margin: 10px;
  width: calc(min(800px, 100vw));
  text-align: center;
  border-radius: 5px;
}

.spotify-preview {
  border-radius: 12px;
  margin: 0;
}

.spotify-preview + p {
  margin-top: 0;
}
.float-image {
  float: right;
  margin: 5px;
}

.bottom-padding {
  margin-top: 50px;
}

.crimee-no-7-page a {
  color: rgb(39, 144, 235);
}