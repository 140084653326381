.welcome-full-image {
  width: 100%;
  z-index: 0;
}

.welcome-content {
  text-align: center;
  width: 100%;
  height: 100vh;
  padding: 5px;
}

.welcome-content-text {
  position: relative;
  z-index: 999;
}

.welcome-content a {
  color: rgb(105, 33, 33);
}

.welcome-content a:hover {
  font-weight: bold;
}

.almost-musical {
  padding: 10px;
  background-color: white;
  text-align: center;
  font-weight: bolder;
}

.background-img {
  z-index: 0;
}

.difference-text {
  width: calc(max(100vw, 600px));
  opacity: 0.2;
  color: black;
  position: fixed;
  top: 100px;
  left: 0;
  font-size: 50pt;
  mix-blend-mode: color-burn;
  z-index: 1;
}