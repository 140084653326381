#the-404-page {
  height: 100vh;
  width: 100vw;
  background-size: calc(min(800px, 100%));
  background-repeat: no-repeat;
  background-position-x: center;
}

.the-404-text {
  opacity: 0.7;
  text-align: center;
  padding-top: 50px;
}